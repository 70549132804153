import { Formik, Form } from 'formik'
import Renderer from '../form/richEditorRenderer'
import React, { FunctionComponent } from 'react'
import FloatingLabel from '../form/floatingLabel'
import dynamic from 'next/dynamic'
import Strings from '../strings'

const RichEditor = dynamic(() => import('../form/richEditor'), {
  ssr: false,
})

type Props = {
  edit?: boolean
  saveBlock: (index: number, blockTitle: BlockTitleType) => void
  index: number
} & BlockTitleType

export type BlockTitleType = {
  title: string
  description: string
}

type Errors = {
  description?: string
}

const BlockTitle: FunctionComponent<Props> = ({ edit, title, description, saveBlock, index }) => {
  const initialValues: BlockTitleType = {
    title,
    description,
  }

  const handleSubmit = async (blockTitle: BlockTitleType) => {
    saveBlock(index, blockTitle)
  }

  const validate = (values: BlockTitleType) => {
    const errors = {} as Errors

    // if (!values.description || !values.description.blocks || (values.description.blocks.length === 1 && values.description.blocks[0].text === '')) {
    //   errors.description = 'Beschreibung fehlt'
    // }

    return errors
  }

  if (!edit) {
    return (
      <>
        <h1 className="mb=2">{title}</h1>
        <Renderer raw={description} />
      </>
    )
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate}>
      {({ values, isSubmitting, setFieldValue }) => (
        <Form>
          <FloatingLabel type="text" name="title" placeholder={`${Strings.de.components.check.blockTitle}*`} required />
          <RichEditor
            name="description"
            placeholder={`${Strings.de.components.check.enterBlockDescription}*`}
            value={values.description}
            onChange={setFieldValue}
          />
          <div className="flex justify-end">
            <button type="submit" className="c=prim ml=1 r-circle py=1 px=2 mt=3" disabled={isSubmitting}>
              {Strings.de.components.check.save}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default BlockTitle
