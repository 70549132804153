import Strings from '../strings'
import React, { FunctionComponent } from 'react'

type Props = {
  id: string
  token: string
  duplicate?: boolean
  onChange: (event: React.ChangeEvent<HTMLSelectElement>, id: string, token: string) => void
}
const ActionSelect: FunctionComponent<Props> = ({ id, token, onChange, duplicate }) => (
  <select
    className="arrow-down b r c=text p=1"
    onChange={event => {
      onChange(event, id, token)
    }}
  >
    <option value="">{Strings.de.components.table.actions}</option>
    <option value="edit">{Strings.de.components.table.edit}</option>
    {!!duplicate && <option value="copy">{Strings.de.components.table.duplicate}</option>}
    <option value="delete">{Strings.de.components.table.delete}</option>
  </select>
)

export default ActionSelect
