import React from 'react'
import { IconAdd } from '../icons'
import Strings from '../strings'

interface Props {
  addQuestion: (type: string) => void
}

const QuestionAdd: React.FunctionComponent<Props> = ({ addQuestion }) => (
  <div className="sticky bottom c=text py=2 flex">
    <button
      className="c=text p=1 m=1 flex align-center"
      onClick={() => {
        addQuestion('yesNo')
      }}
    >
      <IconAdd className="mr=1" />
      {Strings.de.components.question.yesNoQuestion}
    </button>
    <button
      className="c=text p=1 m=1 flex align-center"
      onClick={() => {
        addQuestion('condition')
      }}
    >
      <IconAdd className="mr=1" />
      {Strings.de.components.question.conditionQuestion}
    </button>
    <button
      className="c=text p=1 m=1 flex align-center"
      onClick={() => {
        addQuestion('formula')
      }}
    >
      <IconAdd className="mr=1" />
      {Strings.de.components.question.formulaQuestion}
    </button>
  </div>
)

export default QuestionAdd
