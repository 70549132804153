import React, { ChangeEvent, FunctionComponent } from 'react'

type Props = {
  checked?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  label?: string
  name?: string
  value?: string
}

const Toggle: FunctionComponent<Props> = ({ checked, onChange, label, name, value }) => {
  return (
    <label className="toggle p=1 mr=1">
      <input type="checkbox" checked={checked} onChange={onChange} name={name} value={value} />
      <span className="mr=1"></span>
      {label}
      <style jsx>{`
        .toggle {
          color: rgba(0, 0, 0, 0.87);
        }
      `}</style>
    </label>
  )
}

export default Toggle
