import SidePanel from '../sidePanel'
import React, { useState } from 'react'
import { connect, FormikContextType } from 'formik'
import { getQuestion, getQuestionPosition, getQuestionTypeDisplayName, existsInBlocks } from '../../utils/checks'

import RichEditorRenderer from '../form/richEditorRenderer'
import LinkedQuestionBlocks from './linkedQuestionBlocks'
import EndTextEdit from './endTextEdit'
import { QuestionType, QuestionTypes, Target } from './question'
import { BlockType } from '../check/block'
import { IconLinkChain } from '../icons'
import { emptyRichText } from '../form/richEditor'
import Strings from '../strings'

interface Props {
  name: string
  blocks: BlockType[]
  className?: string
  displayName?: string
  index?: number
}

const LinkedQuestion: React.FunctionComponent<Props & { formik: FormikContextType<QuestionType<QuestionTypes>> }> = ({
  formik,
  index,
  name,
  blocks,
  displayName,
  className,
}) => {
  const { values, setFieldValue } = formik
  const { uid, title, logic } = values

  const hasIndex = index !== undefined
  const endValueString = `logic.${name}${hasIndex ? `.${index}` : ''}.end`
  const linkValueString = `logic.${name}${hasIndex ? `.${index}` : ''}.link`
  const logicValue: Target = hasIndex ? logic[name][index] : logic[name]

  const [openState, setOpenState] = useState(false)
  const [isEnd, setIsEnd] = useState(!!logicValue.end)
  const [linked, setLinked] = useState(existsInBlocks(blocks, logicValue.link) ? logicValue.link : null)
  const [linkedQuestion, setLinkedQuestion] = useState(getQuestion(linked, blocks))
  const [linkedQuestionPosition, setLinkedQuestionPosition] = useState<number[]>(getQuestionPosition(linked, blocks))

  const handleEndToggle = () => {
    if (isEnd) {
      setIsEnd(false)
      setFieldValue(endValueString as any, null)
    } else {
      setIsEnd(true)
      setFieldValue(linkValueString as any, null)
    }
  }

  const handleToggle = (
    event: MouseEvent | React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
  ) => {
    if (!(event.currentTarget as HTMLElement).contains(event.target as HTMLButtonElement)) {
      return
    }
    setOpenState(!openState)
  }

  const handleLink = (tempLinked: string) => {
    setLinked(tempLinked)
    setLinkedQuestion(getQuestion(tempLinked, blocks))
    setOpenState(false)
    setFieldValue(linkValueString as any, tempLinked)
    setLinkedQuestionPosition(getQuestionPosition(tempLinked, blocks))
  }
  return (
    <>
      {!isEnd && (
        <button
          type="button"
          className={`${className ? className : 'w=1/2'} p=1 grow=1 flex link-button text-align=l relative`}
          onClick={handleToggle}
        >
          {linked && linkedQuestion ? (
            <div>
              {getQuestionTypeDisplayName(linkedQuestion.type, linkedQuestionPosition[0], linkedQuestionPosition[1])}
            </div>
          ) : (
            Strings.de.components.question.choose
          )}
          <span className="absolute right link-chain">
            <IconLinkChain />
          </span>
        </button>
      )}

      <EndTextEdit cb={handleEndToggle} isEnd={isEnd} name={endValueString} value={logicValue.end || emptyRichText} />
      {!isEnd && (
        <SidePanel openState={openState} closeCallback={() => setOpenState(!openState)}>
          <div className="flex column align-center">
            <div className="w=3/4">
              <p className="text-l">{Strings.de.components.question.link}</p>
              <h2 className="my=2">{displayName}</h2>
              {title && <RichEditorRenderer raw={title}></RichEditorRenderer>}
            </div>
            <div className="c=sec w=full py=4 px=3 mt=3">
              <LinkedQuestionBlocks handleLink={handleLink} questionUid={uid} blocks={blocks} linked={linked} />
            </div>
          </div>
        </SidePanel>
      )}
      <style jsx>{`
        .link-button {
          background-color: rgba(235, 235, 235, 0.5);
        }
        .link-chain {
          right: 10px;
        }
      `}</style>
    </>
  )
}

export default connect<Props, QuestionType<QuestionTypes>>(LinkedQuestion)
