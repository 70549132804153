import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Router from 'next/router'

import Toolbar from '../toolbar'
import { updateCheck, copyCheck, deleteCheck, getChecks, Check } from '../../utils/checks'
import { WithAdminSyncProps } from '../../utils/auth'
import { IconSearch } from '../icons'
import Pagination from '../table/pagination'
import LoadingSpinner from '../loadingSpinner'
import Strings from '../strings'
import SortBy from '../table/sortBy'

interface Props extends WithAdminSyncProps {
  checks: Check[]
  page: number
  perPage: number
  totalRecords: number
  categoryId?: string
}

const CheckTable: React.FunctionComponent<Props> = props => {
  const { token, categoryId } = props
  const [checks, setChecks] = useState(props.checks)
  const [page, setPage] = useState(props.page)
  const [perPage, setPerPage] = useState(props.perPage)
  const [totalRecords, setTotalRecords] = useState(props.totalRecords)
  const [searchValue, setSearchValue] = useState('')
  const [refresh, setRefresh] = useState(false)
  const [categories, setCategories] = useState(categoryId ? [categoryId] : [])
  const [isSubmitting, setSubmitting] = useState(false)
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc')
  const [sortBy, setSortBy] = useState<'type' | 'title' | 'customTitle' | 'creatorName' | 'createdAt'>('createdAt')

  const doRefresh = () => setRefresh(!refresh)
  const stopSubmitting = () => setSubmitting(false)

  const setResponseStates = ({ perPage, page, data: checks, totalRecords }) => {
    setPerPage(perPage)
    setPage(page)
    setChecks(checks)
    setTotalRecords(totalRecords)
  }

  useEffect(() => {
    setCategories(categoryId ? [categoryId] : [])
  }, [categoryId])

  useEffect(() => {
    setPage(0)
    doRefresh()
  }, [searchValue])

  useEffect(() => {
    setSubmitting(true)
    if (!categories.length) {
      getChecks(token, page, perPage, { searchValue, sortBy, sortDirection })
        .then(setResponseStates)
        .finally(stopSubmitting)
    } else {
      getChecks(token, page, perPage, { searchValue, categoryIds: categories.join(), sortBy, sortDirection })
        .then(setResponseStates)
        .finally(stopSubmitting)
    }
  }, [page, perPage, totalRecords, refresh, categories, sortBy, sortDirection])

  async function handleStart(checkId: string) {
    setSubmitting(true)
    Router.push(`/app/results/add/[checkId]`, `/app/results/add/${checkId}`)
  }

  const hasToken = !(token === '')

  const handlePageJump = (e, id) => {
    if (e.target === e.currentTarget) {
      Router.push(`/app/results/add/[checkId]`, `/app/results/add/${id}`)
    }
  }

  return (
    <React.Fragment>
      {hasToken && (
        <Toolbar>
          <div className="w=1/2 md:w=1/4 bb flex align-center">
            <label htmlFor="search">
              <IconSearch className="ml=3" />
            </label>
            <input
              id="search"
              type="search"
              placeholder={Strings.de.components.check.searchChecks}
              value={searchValue}
              onChange={event => setSearchValue(event.target.value)}
              className="py=3 px=2 c=text w=full"
            ></input>
          </div>
        </Toolbar>
      )}
      <div className="table__container table__container--wide">
        {isSubmitting && <LoadingSpinner fullOverlay={true} />}
        <Table classes={{ root: 'c=text r elevated table table--wide' }}>
          <TableHead>
            <TableRow>
              <TableCell>
                {Strings.de.components.check.typeShort}{' '}
                <SortBy sort={'type'} sortBy={sortBy} setSortBy={setSortBy} setDirection={setSortDirection} />
              </TableCell>
              <TableCell>
                {Strings.de.components.check.name}{' '}
                <SortBy sort={'title'} sortBy={sortBy} setSortBy={setSortBy} setDirection={setSortDirection} />
              </TableCell>
              {hasToken && <TableCell className="sticky right c=text">{Strings.de.components.table.actions}</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody className="table__body">
            {checks &&
              checks
                .filter(check => check.valid && check.active)
                .map(check => {
                  if (!check) {
                    return
                  }

                  const { id, title, active, valid, pdfLabels } = check

                  return (
                    <TableRow key={id} className="hover:c=sec pointer">
                      <TableCell
                        onClick={e => {
                          handlePageJump(e, id)
                        }}
                      >
                        {pdfLabels.title}
                      </TableCell>
                      <TableCell
                        onClick={e => {
                          handlePageJump(e, id)
                        }}
                      >
                        {title}
                      </TableCell>
                      {hasToken && (
                        <TableCell
                          className="sticky right c=text"
                          onClick={e => {
                            handlePageJump(e, id)
                          }}
                        >
                          <button
                            className="c=text b p=1 w=full r"
                            onClick={() => {
                              handleStart(id)
                            }}
                          >
                            {Strings.de.components.check.start}
                          </button>
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })}
            {hasToken && (
              <Pagination
                count={totalRecords}
                rowsPerPage={perPage}
                page={page}
                onChangePage={(event, newPage) => setPage(newPage)}
                onChangeRowsPerPage={event => setPerPage(parseInt(event.target.value))}
              />
            )}
          </TableBody>
        </Table>
      </div>
      <style jsx>{`
        .opacity {
          opacity: 0.4;
        }
      `}</style>
    </React.Fragment>
  )
}

export default CheckTable
