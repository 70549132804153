import React from 'react'

interface Props {
  linked: boolean
}

const QuestionStatus: React.FunctionComponent<Props> = ({ linked }) => (
  <React.Fragment>
    <span className={`r-circle ${linked ? '' : 'c=err'} ml mr=1 question-status`}></span>
    <style jsx>{`
      .question-status {
        padding: 4px;
      }
    `}</style>
  </React.Fragment>
)

export default QuestionStatus
