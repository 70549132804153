import { connect, Field, FormikContextType } from 'formik'

import LinkedQuestion from './linkedQuestion'
import { QuestionType, Target, target } from './question'
import { BlockType } from '../check/block'
import React, { useEffect } from 'react'
import RichEditor, { emptyRichText } from '../form/richEditor'
import Strings from '../strings'

interface Props {
  blocks: BlockType[]
}

export type Formula = {
  gt: Target
  hintGt: string
  lt: Target
  hintLt: string
  eq: Target
  hintEq: string
  operator: string
  compareValue: number
}

const QuestionFormula: React.FunctionComponent<Props & { formik: FormikContextType<QuestionType<Formula>> }> = ({
  formik,
  blocks,
}) => {
  const { values, setFieldValue } = formik
  const { logic } = values

  useEffect(() => {
    if (
      !logic.hasOwnProperty('compareValue') &&
      !logic.hasOwnProperty('eq') &&
      !logic.hasOwnProperty('gt') &&
      !logic.hasOwnProperty('lt') &&
      !logic.hasOwnProperty('operator')
    ) {
      setFieldValue('logic', {
        compareValue: 0,
        eq: target,
        hintEq: emptyRichText,
        gt: target,
        hintGt: emptyRichText,
        lt: target,
        hintLt: emptyRichText,
        operator: '+',
        ...logic,
      } as Formula)
    }
  }, [])

  return (
    <fieldset className="flex wrap">
      <div className="flex mb=3 grow=1">
        <label className="c=sec w=1/2 p=3" htmlFor="logic.operator">
          {Strings.de.components.question.operator}
        </label>
        {logic.hasOwnProperty('operator') && (
          <Field
            required
            component="select"
            className="w=1/2 p=3 arrow-down link-button"
            name="logic.operator"
            value={logic.operator || '+'}
          >
            <option value="+">{Strings.de.components.question.addition}</option>
            <option value="-">{Strings.de.components.question.subtraction}</option>
            <option value="*">{Strings.de.components.question.multiplication}</option>
            <option value="/">{Strings.de.components.question.division}</option>
          </Field>
        )}
      </div>
      <div className="flex mb=3 grow=1">
        <label className="c=sec w=1/2 p=3" htmlFor="logic.compareValue">
          Vergleichswert
        </label>
        {logic.hasOwnProperty('compareValue') && (
          <Field
            required
            className="w=1/2 p=3 link-button"
            type="number"
            pattern="[0-9]+([\.,][0-9]+)?"
            name="logic.compareValue"
          />
        )}
      </div>
      <div className="flex wrap mb=3 grow=1">
        <details className="my=2 w=full">
          <summary className="arrow-down c=sec p=1 pointer">{Strings.de.components.question.output}</summary>
          <RichEditor
            placeholder={Strings.de.components.question.enterOutput}
            name={`logic.hintGt`}
            value={logic.hintGt}
            onChange={setFieldValue}
          />
        </details>
        <div className="c=prim w=1/2 p=1 text-align=c">{Strings.de.components.question.formulaGreater}</div>
        {logic.hasOwnProperty('gt') && (
          <LinkedQuestion
            name="gt"
            blocks={blocks}
            displayName={`${Strings.de.components.question.formulaQuestion} -- ${Strings.de.components.question.formulaGreater}`}
          />
        )}
      </div>
      <div className="flex wrap mb=3 grow=1">
        <details className="my=2 w=full">
          <summary className="arrow-down c=sec p=1 pointer">{Strings.de.components.question.output}</summary>
          <RichEditor
            placeholder={Strings.de.components.question.enterOutput}
            name={`logic.hintLt`}
            value={logic.hintLt}
            onChange={setFieldValue}
          />
        </details>
        <div className="c=prim w=1/2 p=1 text-align=c">{Strings.de.components.question.formulaLess}</div>
        {logic.hasOwnProperty('lt') && (
          <LinkedQuestion
            name="lt"
            blocks={blocks}
            displayName={`${Strings.de.components.question.formulaQuestion} -- ${Strings.de.components.question.formulaLess}`}
          />
        )}
      </div>
      <div className="flex wrap mb=3 grow=1">
        <details className="my=2 w=full">
          <summary className="arrow-down c=sec p=1 pointer">{Strings.de.components.question.output}</summary>
          <RichEditor
            placeholder={Strings.de.components.question.enterOutput}
            name={`logic.hintEq`}
            value={logic.hintEq}
            onChange={setFieldValue}
          />
        </details>
        <div className="c=prim w=1/2 p=1 text-align=c">{Strings.de.components.question.formulaEqual}</div>
        {logic.hasOwnProperty('eq') && (
          <LinkedQuestion
            name="eq"
            blocks={blocks}
            displayName={`${Strings.de.components.question.formulaQuestion} -- ${Strings.de.components.question.formulaEqual}`}
          />
        )}
      </div>
    </fieldset>
  )
}

export default connect<Props, QuestionType<Formula>>(QuestionFormula)
