import React, { useState, useEffect, Fragment, FunctionComponent } from 'react'
import uuid from 'uuid/v1'

import QuestionAdd from '../question/questionAdd'
import QuestionDragField from '../question/questionDragField'
import Question, { QuestionType, QuestionTypes, Target } from '../question/question'
import Edit from '../edit'
import BlockTitle from './blockTitle'
import { IconDelete, IconCopy } from '../icons'
import { emptyRichText } from '../form/richEditor'
import { validateCheckLinks } from '../../utils/checks'
import Strings from '../strings'
import { useRef } from 'react'

interface Props {
  blockUid: string
  title: string
  description: string
  index: number
  blocks: BlockType[]
  questions: QuestionType<QuestionTypes>[]
  saveBlock: (index: number, block: any) => void
  validateBlocks: (index: number, valid: boolean) => void
  deleteBlock: (uid: string, index: number) => void
  copyBlock: (index: number) => void
  handleMove: (uid: string) => void
  moving: boolean
  moveQuestion: (block: number, question: number) => void
}

export type BlockType = {
  uid: string
  title: string
  description: string
  questions: QuestionType<QuestionTypes>[]
}

const Block: FunctionComponent<Props> = ({
  blockUid,
  title,
  description,
  index,
  saveBlock,
  blocks,
  questions: questionsData,
  validateBlocks,
  deleteBlock,
  handleMove,
  moving = false,
  moveQuestion,
  copyBlock,
}) => {
  const [questions, setQuestions] = useState(questionsData)
  const [editingState, setEditingState] = useState<string | null>(title ? null : blockUid)
  const [validQuestions, setValidQuestions] = useState<boolean[]>(
    questions.map(question => validateCheckLinks(question, blocks)),
  )

  const saveQuestion = (questionIndex: number, question: QuestionType<QuestionTypes>) => {
    const tempQuestions = [...questions]
    tempQuestions[questionIndex] = question
    setQuestions(tempQuestions)
    setEditingState(null)
  }

  useEffect(() => {
    validateBlocks(index, !(validQuestions.filter(question => !question).length === 0))
    saveBlock(index, { questions })
  }, [questions, validQuestions])

  const currentQuestion = useRef<HTMLDivElement>(null)

  const addQuestion = (type: string) => {
    const tempQuestions = [...questions]
    const tempQuestion: QuestionType<QuestionTypes> = {
      uid: uuid(),
      type,
      title: emptyRichText,
      description: emptyRichText,
      logic: {} as QuestionTypes,
    }
    setQuestions(tempQuestions.concat(tempQuestion))
    setEditingState(tempQuestion.uid)
  }

  const cancelQuestion = () => {
    setEditingState(null)
  }

  const deleteQuestion = (uid: string, index: number) => {
    if (confirm(Strings.de.components.check.confirmDeleteQuestion)) {
      const tempQuestions = questions.filter(el => el.uid !== uid)
      setValidQuestions(validQuestions.filter((el, tmpIndex) => tmpIndex !== index))
      setQuestions(tempQuestions)
    }
  }
  const copyQuestion = (index: number) => {
    setValidQuestions(validQuestions.concat(validQuestions[index]))
    setQuestions(questions.concat({ ...questions[index], uid: uuid() }))
    if (currentQuestion && currentQuestion.current) {
      currentQuestion!.current!.scrollIntoView(true)
    }
  }

  const validateBlock = (i: number, valid: boolean) => {
    setValidQuestions(validQuestions => validQuestions.map((v, i) => (i === index ? valid : v)))
  }

  return (
    <div className="w=full md:w=2/3 ml=2 md:ml=6 py=4">
      <Edit
        key={blockUid}
        uid={blockUid}
        edit={blockUid === editingState}
        editable={!editingState && !moving}
        focus={setEditingState}
        deletable={false}
        handleDelete={() => {
          deleteBlock(blockUid, index)
        }}
      >
        <BlockTitle
          title={title}
          description={description}
          saveBlock={(index, values) => {
            saveBlock(index, values)
            setEditingState(null)
          }}
          index={index}
        />
      </Edit>
      <div className="absolute top right py=3 px=2">
        {blocks.length > 1 && (
          <button className="flex align-center pointer c=prim-alt" onClick={() => deleteBlock(blockUid, index)}>
            <IconDelete className="mr=1" /> {Strings.de.components.check.deleteBlock}
          </button>
        )}
        <button className="flex align-center pointer c=prim-alt" onClick={() => copyBlock(index)}>
          <IconCopy className="mr=1" /> {Strings.de.components.check.copyBlock}
        </button>
      </div>
      {moving && (
        <QuestionDragField
          onClick={() => {
            moveQuestion(index, 0)
          }}
        />
      )}
      {questions.map((question, questionIndex) => {
        return (
          <div ref={currentQuestion} key={questionIndex}>
            <Edit
              uid={question.uid}
              edit={question.uid === editingState}
              editable={!editingState && !moving}
              focus={setEditingState}
              deletable={true}
              handleDelete={() => {
                deleteQuestion(question.uid, questionIndex)
              }}
              handleCopy={() => {
                copyQuestion(questionIndex)
              }}
              handleMove={handleMove}
            >
              <Question
                key={question.uid}
                question={question}
                questionIndex={questionIndex}
                blocks={blocks}
                blockIndex={index}
                saveQuestion={saveQuestion}
                cancelQuestion={cancelQuestion}
                validateBlock={validateBlock}
              />
            </Edit>
            {moving && (
              <QuestionDragField
                onClick={() => {
                  moveQuestion(index, questionIndex + 1)
                }}
              />
            )}
          </div>
        )
      })}
      {!editingState && <QuestionAdd addQuestion={addQuestion} />}
    </div>
  )
}

export default Block
