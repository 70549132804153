import { connect, FormikContextType } from 'formik'
import LinkedQuestion from './linkedQuestion'
import { QuestionType, Target, target } from './question'
import { BlockType } from '../check/block'
import React, { useEffect } from 'react'
import RichEditor, { emptyRichText } from '../form/richEditor'
import Strings from '../strings'

interface Props {
  blocks: BlockType[]
}

export type YesNo = {
  yes: Target
  no: Target
  hintYes: string
  hintNo: string
}

const QuestionYesNo: React.FunctionComponent<Props & { formik: FormikContextType<QuestionType<YesNo>> }> = ({
  formik,
  blocks,
}) => {
  const { values, setFieldValue } = formik
  const { logic } = values

  useEffect(() => {
    if (!logic.hasOwnProperty('yes') && !logic.hasOwnProperty('no')) {
      setFieldValue('logic', {
        yes: target,
        no: target,
        hintYes: emptyRichText,
        hintNo: emptyRichText,
        ...logic,
      } as YesNo)
    }
  }, [])

  return (
    <fieldset>
      <div className="flex wrap mb=3">
        <details className="my=2 w=full">
          <summary className="arrow-down bb c=sec p=1 pointer">{Strings.de.components.question.output}</summary>
          <RichEditor
            placeholder={Strings.de.components.question.enterOutput}
            name={`logic.hintYes`}
            value={logic.hintYes}
            onChange={setFieldValue}
          />
        </details>

        <div className="c=prim w=1/2 p=1 grow=1 bb text-align=c">{Strings.de.components.question.yes}</div>
        {logic.hasOwnProperty('yes') && (
          <LinkedQuestion
            name="yes"
            displayName={`${Strings.de.components.question.yesNoQuestion} -- ${Strings.de.components.question.yes}`}
            blocks={blocks}
          />
        )}
      </div>
      <div className="flex wrap mb=3">
        <details className="my=2 w=full">
          <summary className="arrow-down bb c=sec p=1 pointer">{Strings.de.components.question.output}</summary>
          <RichEditor
            placeholder={Strings.de.components.question.enterOutput}
            name={`logic.hintNo`}
            value={logic.hintNo}
            onChange={setFieldValue}
          />
        </details>

        <div className="c=prim w=1/2 p=1 grow=1 bb text-align=c">{Strings.de.components.question.no}</div>
        {logic.hasOwnProperty('no') && (
          <LinkedQuestion
            name="no"
            displayName={`${Strings.de.components.question.yesNoQuestion} -- ${Strings.de.components.question.no}`}
            blocks={blocks}
          />
        )}
      </div>
      <style jsx>{`
        .bb {
          border-color: #f8f9f9;
        }
      `}</style>
    </fieldset>
  )
}

export default connect<Props, QuestionType<YesNo>>(QuestionYesNo)
