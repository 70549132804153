import React, { useState } from 'react'

type Props = {
  sort: string
  sortBy: string
  setSortBy: React.Dispatch<React.SetStateAction<'createdAt' | 'type' | 'title' | 'customTitle' | 'creator'>>
  setDirection: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>
}

const SortBy = ({ sort, sortBy, setSortBy, setDirection }) => {
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc')
  const sortArrow = sortDirection === 'asc' ? '▲' : '▼'

  const toggleSortDirection = () => {
    setDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
  }

  return (
    <span
      className={`pointer text-s ${sortBy !== sort ? 'opacity' : ''}`}
      onClick={() => {
        setSortBy(sort)
        toggleSortDirection()
      }}
    >
      {sortArrow}

      <style jsx>{`
        .opacity {
          opacity: 0.4;
        }
      `}</style>
    </span>
  )
}

export default SortBy
