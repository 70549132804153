import React, { useState, useEffect } from 'react'
import { FileMeta, getFiles } from '../../utils/files'
import { createUrl } from '../../utils/fetch'
import { Field } from 'formik'
import Strings from '../strings'

const FileChooser = ({ token }) => {
  const [files, setFiles] = useState<FileMeta[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const result = await getFiles(token)
      setFiles(result.filter(meta => ['svg', 'png', 'jpg', 'jpeg', 'gif'].indexOf(meta.extension) >= 0))
    }
    fetchData()
  }, [])

  return (
    <fieldset className="mt=2">
      {!!files.length && (
        <legend className="c=prim inline-block py=1 px=2 r mb=2">{Strings.de.components.form.checkLogo}</legend>
      )}

      <div className="flex-grid mb=2">
        {files.map((file, index) => (
          <label className="w=1/3 p=4 c=sec mb=1 relative" key={index}>
            <Field name="logoId" id="logoId" type="radio" className="absolute top left m=1" value={file.id} />
            <img src={createUrl(`/v1/files/${file.id}`).toJSON()} />
          </label>
        ))}
      </div>
    </fieldset>
  )
}

export default FileChooser
