import { Tabs, TabList, Tab, TabPanels, TabPanel, TabProps } from '@reach/tabs'
import React, { useState } from 'react'

import RichEditorRenderer from '../form/richEditorRenderer'
import { getQuestionTypeDisplayName, getQuestionPosition } from '../../utils/checks'
import { IconCheckMark } from '../icons'
import { BlockType } from '../check/block'

interface Props {
  blocks: BlockType[]
  questionUid: string
  linked: string | null
  handleLink: (uid: string) => void
}

const SelectionTab: React.FunctionComponent<TabProps> = props => {
  const { isSelected, children } = props

  return (
    <Tab {...props} className={`p=3 r relative ${isSelected ? 'elevated c=text tab' : ''}`}>
      {children}
    </Tab>
  )
}

const LinkedQuestionBlocks: React.FunctionComponent<Props> = ({ blocks, handleLink, questionUid, linked }) => {
  const [tabIndexLinked, setTabIndexLinked] = useState(
    linked ? getQuestionPosition(linked, blocks)[0] : getQuestionPosition(questionUid, blocks)[0],
  )

  const handleTabsChange = (event: React.MouseEvent<HTMLElement, MouseEvent>, index: number) => {
    event.preventDefault()
    setTabIndexLinked(index)
  }

  return (
    <Tabs className="r" index={tabIndexLinked} defaultIndex={1} onChange={() => {}}>
      <TabList className="flex tablist">
        {blocks.map((block, index) => (
          <SelectionTab
            onClick={event => {
              handleTabsChange(event, index)
            }}
            key={`tab-${questionUid}-${index}`}
          >
            {index + 1}
          </SelectionTab>
        ))}
      </TabList>

      <TabPanels className="p=1 elevated c=text tab-panels">
        {blocks.map(({ questions }, blockIndex) => (
          <TabPanel key={`panel-${blockIndex}`}>
            {questions &&
              questions.map(({ title, uid, type }, questionIndex) => {
                const isLinked = uid === linked
                const isCurrent = uid === questionUid
                return (
                  <div
                    key={`linked-${questionIndex}`}
                    onClick={
                      isCurrent
                        ? () => {}
                        : () => {
                            handleLink(uid)
                          }
                    }
                    className={`my=2 p=2 relative ${isCurrent ? 'disabled' : 'pointer hover:c=sec hover:b'} ${
                      isLinked ? 'linked' : ''
                    }`}
                  >
                    {isLinked && <IconCheckMark className="absolute top right checkmark" />}
                    <hr />
                    <span className="c=prim inline-block mt=1 mb=1 px=1 r question-label">
                      {getQuestionTypeDisplayName(type, blockIndex, questionIndex)}
                    </span>

                    <RichEditorRenderer raw={title} />
                  </div>
                )
              })}
          </TabPanel>
        ))}
      </TabPanels>
      <style jsx>{`
        .tablist {
          overflow-x: auto;
        }
        .disabled {
          opacity: 0.5;
        }
        .question-label {
          transform: translateY(-50%);
        }
        .linked {
          border: 1px solid #d9dbda;
        }
        .checkmark {
          right: 10px;
          transform: translateY(-50%);
        }
      `}</style>
    </Tabs>
  )
}

export default LinkedQuestionBlocks
