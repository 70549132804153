import React from 'react'
import { IconMove } from '../icons'

const QuestionDragField = props => (
  <div {...props} className="c=text r p=3 flex align-center justify-center dragfield pointer">
    <span className="w=full flex justify-center">
      <span className="absolute move-icon c=text p=2">
        <IconMove className="c=prim-alt" />
      </span>
      <hr className="c=prim-alt w=full bb" />
    </span>
    <style jsx>{`
      .dragfield > span {
        display: none;
      }
      .dragfield:hover > span {
        display: flex;
      }
      .move-icon {
        transform: translateY(-50%);
      }
    `}</style>
  </div>
)

export default QuestionDragField
