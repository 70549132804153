import React from 'react'
import { Formik, Form, FormikHelpers, FormikErrors } from 'formik'
import { emptyRichText } from '../form/richEditor'
import { updateCheck, createCheck } from '../../utils/checks'
import CategorySelect from '../form/categorySelect'
import { WithAdminSyncProps } from '../../utils/auth'
import { ResponseWithError, ResponseWithId, DateMeta } from '../../utils/fetch'
import FloatingLabel from '../form/floatingLabel'
import { IconAdd, IconDelete } from '../icons'
import { useState, Fragment } from 'react'
import { BlockType } from './block'
import FileChooser from '../form/fileChooser'
import dynamic from 'next/dynamic'
import Strings from '../strings'
import Router from 'next/router'

const RichEditor = dynamic(() => import('../form/richEditor'), {
  ssr: false,
})

interface Props extends WithAdminSyncProps {
  meta?: Meta
  saveCallback: (response: ResponseWithId) => void
}

export type Meta = {
  id?: string
  documentTitle: string
  logoId: string
  title: string
  norm: string
  pdfLabels: {
    title: string
    norm: string
    dynamic?: Dynamic[]
    user: {
      product: string
      createdBy: string
    }
    result: string
    questions: string
    documents: string
  }
  categories: string[]
  description: string
  blocks?: BlockType[]
  active?: boolean
  valid?: boolean
} & DateMeta

type Errors = {
  description?: string
}

type Dynamic = {
  label: string
  field: string
}

const CheckMetaForm: React.FunctionComponent<Props> = ({
  meta = {
    documentTitle: '',
    logoId: null,
    title: '',
    norm: '',
    description: emptyRichText,
    pdfLabels: {
      title: 'Titel',
      norm: 'Richtlinie',
      dynamic: [],
      user: {
        product: 'Erzeugnis',
        createdBy: 'Erstellt von',
      },
      result: 'Ergebnis',
      questions: 'Vollständige Auswertung',
      documents: 'Hinterlegte Dokumente',
    },
    categories: [],
  },
  token,
  saveCallback,
}) => {
  const initialValues = {
    ...meta,
  } as Meta

  delete initialValues.blocks

  const handleSubmit = (meta: Meta, actions: FormikHelpers<Meta>) => {
    const onSuccess = saveCallback

    const onError = (response: ResponseWithError) => {
      actions.setStatus({ message: response.message })
      actions.setErrors(response.errors as FormikErrors<Meta>)
    }

    const onFinally = () => actions.setSubmitting(false)

    if (meta.id) {
      return updateCheck(token, meta.id!, meta)
        .then(onSuccess, onError)
        .finally(onFinally)
    }

    return createCheck(token, meta)
      .then(onSuccess, onError)
      .finally(onFinally)
  }

  const [dynamicFields, setDynamicFields] = useState(meta.pdfLabels.dynamic || [])

  const addDynamicField = () => {
    setDynamicFields(
      dynamicFields.concat([
        {
          label: '',
          field: '',
        },
      ]),
    )
  }

  const deleteDynamicField = (index) => {
    const newDynamicFields = [...dynamicFields]
    const removed = newDynamicFields.splice(index, 1)
    // const removed2 = meta.pdfLabels.dynamic.splice(index, 1)
    setDynamicFields(newDynamicFields)
  }  

  const validate = (values: Meta) => {
    const errors = {} as Errors

    // if (!values.description || !values.description.blocks || (values.description.blocks.length === 1 && values.description.blocks[0].text === '')) {
    //   errors.description = 'Hinweistext fehlt'
    // }

    return errors
  }

  const cancelEdit = (callback?: () => void) => {
    if (!meta.id) {
      Router.push('/admin')
    } else {
      saveCallback({
        id: meta.id,
      })
    }
    if (callback) {
      callback()
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate}>
      {({ values, dirty, handleReset, isSubmitting, setFieldValue }) => (
        <Form>
          <fieldset>
            <legend className="c=prim inline-block py=1 px=2 r mb=1">
              {Strings.de.components.check.documentTitleHead} (PDF)
            </legend>
            <FloatingLabel
              type="text"
              name="documentTitle"
              placeholder={`${Strings.de.components.check.documentTitle}*`}
              required
            />
          </fieldset>

          <FileChooser token={token} />

          <fieldset className="mt=2">
            <legend className="c=prim inline-block py=1 px=2 r mb=1 mt=1">
              {Strings.de.components.check.generalInformation}
            </legend>

            <FloatingLabel
              type="text"
              name="pdfLabels.title"
              placeholder={`${Strings.de.components.check.type}*`}
              required
            />
            <FloatingLabel type="text" name="title" placeholder={`${Strings.de.components.check.title}*`} required />

            {dynamicFields &&
              dynamicFields.map((dynamicField, index) => {
                return (
                  <Fragment key={index}>
                    <FloatingLabel
                      type="text"
                      name={`pdfLabels.dynamic[${index}].label`}
                      placeholder={`${Strings.de.components.check.label}* (${index + 1}. ${
                        Strings.de.components.check.dynamicField
                      })`}
                    />
                    <FloatingLabel
                      type="text"
                      name={`pdfLabels.dynamic[${index}].field`}
                      placeholder={`${index + 1}. ${Strings.de.components.check.dynamicField}*`}
                    />
                    <div className="flex justify-end">
                      <button
                        type="button"
                        className="c=text flex align-center"
                        onClick={() => deleteDynamicField(index)}
                      >
                        <IconDelete className="mr=1" />
                        {Strings.de.components.check.deleteDynamicField}
                      </button>
                    </div>
                  </Fragment>
                )
              })}
            <div className="flex justify-end">
              <button type="button" className="c=text flex align-center" onClick={addDynamicField}>
                <IconAdd className="mr=1" />
                {Strings.de.components.check.addDynamicField}
              </button>
            </div>
          </fieldset>

          <fieldset className="mt=2">
            <legend className="c=prim inline-block py=1 px=2 r mb=2 mt=1">
              {Strings.de.components.check.description}
            </legend>

            <RichEditor
              name="description"
              placeholder={`${Strings.de.components.check.description}*`}
              onChange={setFieldValue}
              value={values!.description}
            />
          </fieldset>

          <fieldset className="mt=2">
            <legend className="c=prim inline-block py=1 px=2 r mb=1 mt=1">Userinformationen (PDF)</legend>

            <FloatingLabel type="text" name="pdfLabels.user.product" placeholder="Label* (Erzeugnis)" required />
            <FloatingLabel type="text" name="pdfLabels.user.createdBy" placeholder="Label* (Erstellt von)" required />
          </fieldset>

          <fieldset className="mt=2">
            <legend className="c=prim inline-block py=1 px=2 r mb=1 mt=1">
              {Strings.de.components.check.result} (PDF)
            </legend>

            <FloatingLabel
              type="text"
              name="pdfLabels.result"
              placeholder={`${Strings.de.components.check.label}* (${Strings.de.components.check.result})`}
              required
            />
          </fieldset>

          <fieldset className="mt=2">
            <legend className="c=prim inline-block py=1 px=2 r mb=1 mt=1">
              {Strings.de.components.check.evaluation} (PDF)
            </legend>

            <FloatingLabel
              type="text"
              name="pdfLabels.questions"
              placeholder={`${Strings.de.components.check.label}* (${Strings.de.components.check.evaluation})`}
              required
            />
          </fieldset>

          <fieldset className="mt=2">
            <legend className="c=prim inline-block py=1 px=2 r mb=1 mt=1">
              {Strings.de.components.check.attachements} (PDF)
            </legend>

            <FloatingLabel
              type="text"
              name="pdfLabels.documents"
              placeholder={`${Strings.de.components.check.label}* (${Strings.de.components.check.attachements})`}
              required
            />
          </fieldset>

          <fieldset className="mt=2">
            <legend className="c=prim inline-block py=1 px=2 r mb=2 mt=1">
              {Strings.de.components.check.categories}
            </legend>
            <CategorySelect multiple={true} token={token} />
          </fieldset>
          <div className="flex justify-end mt=3">
            <button
              type="button"
              onClick={event => {
                cancelEdit()
              }}
              disabled={isSubmitting}
              className="c=text p=1"
            >
              {meta.id ? Strings.de.pages.results.cancel : Strings.de.components.check.cancel}
            </button>
            <button type="submit" className="c=prim ml=1 r-circle px=2 py=1" disabled={isSubmitting}>
              {Strings.de.components.check.save}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default CheckMetaForm
