import React, { FunctionComponent } from 'react'

type Props = {
  raw: string
}

const RichEditorRenderer: FunctionComponent<Props> = ({ raw }) => {
  return <div className="preview" dangerouslySetInnerHTML={{ __html: raw }}></div>
}

export default RichEditorRenderer
