import React, { useState } from 'react'
import Strings from '../strings'

interface Props {
  type: string
  cb: (type: string) => void
}

const QuestionSwitch: React.FunctionComponent<Props> = ({ cb, type: typeData }) => {
  const [type, setType] = useState(typeData)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, type: string) => {
    event.preventDefault()
    cb(type)
    setType(type)
  }

  return (
    <div className="flex my=2">
      <legend className="c=prim inline-block px=1 mb=1 mr">{Strings.de.components.question.type}</legend>
      <button
        className={type === 'yesNo' ? 'bb c=text' : 'c=text'}
        onClick={event => {
          handleClick(event, 'yesNo')
        }}
      >
        {Strings.de.components.question.yesNoQuestion}
      </button>
      <button
        className={type === 'condition' ? 'bb c=text' : 'c=text'}
        onClick={event => {
          handleClick(event, 'condition')
        }}
      >
        {Strings.de.components.question.conditionQuestion}
      </button>
      <button
        className={type === 'formula' ? 'bb c=text' : 'c=text'}
        onClick={event => {
          handleClick(event, 'formula')
        }}
      >
        {Strings.de.components.question.formulaQuestion}
      </button>
    </div>
  )
}

export default QuestionSwitch
