import React, { useState, useEffect } from 'react'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@reach/tabs'
import { Block } from '.'
import { updateCheck, getQuestion, getQuestionPosition } from '../../utils/checks'
import uuid from 'uuid/v1'
import { WithAdminSyncProps } from '../../utils/auth'
import { BlockType } from './block'
import { emptyRichText } from '../form/richEditor'
import Strings from '../strings'

const SelectionTab = props => {
  const { isSelected, children } = props

  const passedProps = { ...props }
  delete passedProps['isSelected']

  return (
    <Tab {...passedProps} className={`p=3 r relative ${isSelected ? 'elevated c=text tab' : ''}`}>
      {children}
    </Tab>
  )
}

interface Props extends WithAdminSyncProps {
  id: string
  blocks: BlockType[]
  valid?: boolean
  setValid: (valid: boolean) => void
}

const CheckBlockForm: React.FunctionComponent<Props> = props => {
  const { token, id } = props
  const [blocks, setBlocks] = useState(props.blocks || [])
  const [tabIndex, setTabIndex] = useState(0)
  const [validBlocks, setValidBlocks] = useState<boolean[]>(blocks.map(block => false))
  const [valid, setValid] = useState<boolean>(props.valid || false)
  const [moving, setMoving] = useState(false)
  const [movingQuestion, setMovingQuestion] = useState('')

  const addBlock = () => {
    setBlocks(
      blocks.concat({
        uid: uuid(),
        title: '',
        description: emptyRichText,
        questions: [],
      }),
    )
  }

  useEffect(() => {
    setTabIndex(blocks.length - 1)
    if (validBlocks.length < blocks.length) {
      setValidBlocks(validBlocks.concat(false))
    }
  }, [blocks.length])

  const saveBlock = (index: number, values: BlockType) => {
    const tempBlocks = [...blocks]
    const block = { ...tempBlocks[index], ...values }
    tempBlocks[index] = block
    setBlocks(tempBlocks)
  }

  const deleteBlock = (uid: string, index: number) => {
    if (confirm(Strings.de.components.check.confirmDeleteBlock)) {
      const tempBlocks = blocks.filter(element => element.uid !== uid)
      setValidBlocks(validBlocks.filter((elment, tmpIndex) => tmpIndex !== index))
      setBlocks(tempBlocks)
    }
  }

  const copyBlock = (index: number) => {
    setBlocks(
      blocks.concat({
        ...blocks[index],
        uid: uuid(),
        questions: blocks[index].questions.map(question => ({ ...question, uid: uuid() })),
      }),
    )
    setValidBlocks(validBlocks.concat(validBlocks[index]))
  }

  const saveBlocks = () => {
    updateCheck(token, id, { blocks })
  }

  const saveValid = () => {
    updateCheck(token, id, { valid })
  }

  const handleTabsChange = (index: number) => {
    setTabIndex(index)
  }

  useEffect(() => {
    setValid(validBlocks.filter(block => block).length < 1)
  }, [validBlocks])

  useEffect(() => {
    props.setValid(valid)
    saveValid()
  }, [valid])

  useEffect(() => {
    saveBlocks()
  }, [blocks])

  if (blocks.length === 0) {
    addBlock()
  }

  const validateBlocks = (index: number, isValid: boolean) => {
    const tempValidBlocks = [...validBlocks]
    tempValidBlocks[index] = isValid
    setValidBlocks(tempValidBlocks)
  }

  const handleMove = (uid: string) => {
    setMoving(true)
    setMovingQuestion(uid)
    document.body.style.cursor = 'grabbing'
  }

  const moveQuestion = (blockIndex: number, questionIndex: number) => {
    setMoving(false)
    document.body.style.cursor = 'auto'
    if (movingQuestion) {
      const tempBlocks = [...blocks]
      const tempQuestion = getQuestion(movingQuestion, blocks)
      const questionPosition = getQuestionPosition(movingQuestion, blocks)

      tempBlocks[blockIndex].questions.splice(questionIndex, 0, tempQuestion)

      if (blockIndex === questionPosition[0] && questionIndex < questionPosition[1]) {
        tempBlocks[questionPosition[0]].questions.splice(questionPosition[1] + 1, 1)
      } else {
        tempBlocks[questionPosition[0]].questions.splice(questionPosition[1], 1)
      }
      setBlocks(tempBlocks)
    }
  }

  return (
    <div className="relative">
      <Tabs className="r" index={tabIndex} onChange={handleTabsChange}>
        <TabList className="flex tablist pr=3">
          {blocks && blocks.map((block, index) => <SelectionTab key={`tab-${index}`}>{index + 1}</SelectionTab>)}
        </TabList>
        <button onClick={addBlock} className="tab absolute right top p=3 c=text ml elevated r">
          +
        </button>

        <TabPanels className="p=1 elevated c=text tab-panels relative">
          {blocks &&
            blocks.map(({ title, description, questions, uid }, blockIndex) => (
              <TabPanel key={`panel-${blockIndex}`}>
                <Block
                  blockUid={uid}
                  title={title}
                  description={description}
                  questions={questions}
                  index={blockIndex}
                  saveBlock={saveBlock}
                  blocks={blocks}
                  validateBlocks={validateBlocks}
                  deleteBlock={deleteBlock}
                  copyBlock={copyBlock}
                  handleMove={handleMove}
                  moving={moving}
                  moveQuestion={moveQuestion}
                />
              </TabPanel>
            ))}
        </TabPanels>
        <style jsx>{`
          .tablist {
            overflow-x: auto;
          }
        `}</style>
      </Tabs>
    </div>
  )
}

export default CheckBlockForm
